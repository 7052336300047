<template>
  <div>
    <div class="row">
      <div class="col-xxl-8">
        <div class="card card-custom gutter-b example example-compact" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">
                {{ post.id == 1 ? $t('About_us.about_us') : '' }}
                {{ post.id == 2 ? $t('About_us.objective') : '' }}
                {{ post.id == 3 ? $t('About_us.contact_us') : '' }}
                (Thai)
              </h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group
              v-if="post.id == 1"
              id="fieldset-1"
              description=""
              :label="$t('About_us.title')"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="post.title_th"
                trim
                placeholder="untitle"
                :state="!(post.title_th == '')"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="fieldset-1" description="" :label="$t('About_us.body')" label-for="input-1">
              <ckeditor :editor="editor" v-model="post.body_th" :config="editorConfig"></ckeditor>
            </b-form-group>
          </div>
        </div>

        <div class="card card-custom gutter-b example example-compact" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">
                {{ post.id == 1 ? $t('About_us.about_us') : '' }}
                {{ post.id == 2 ? $t('About_us.objective') : '' }}
                {{ post.id == 3 ? $t('About_us.contact_us') : '' }}
                (English)
              </h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group
              v-if="post.id == 1"
              id="fieldset-1"
              description=""
              :label="$t('About_us.title')"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="post.title_en"
                trim
                placeholder="untitle"
                :state="!(post.title_en == '')"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="fieldset-1" description="" :label="$t('About_us.body')" label-for="input-1">
              <ckeditor :editor="editor" v-model="post.body_en" :config="editorConfig"></ckeditor>
            </b-form-group>
          </div>
        </div>

        <b-overlay :show="isSaving" no-wrap> </b-overlay>
      </div>

      <div class="col-xxl-4">
        <div class="card card-custom gutter-b" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">
                {{ $t('About_us.publish') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-button variant="primary" class="mb-5" block @click="savePost">Save</b-button>
          </div>
          <!--end::Body-->
          <b-overlay :show="isSaving" no-wrap> </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {SAVE_POST, QUERY, GET_PARENT, UPLOAD_FILE, UPLOAD_ATTACH, DELETE_ATTACH} from '../services.js';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';

import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: 'List',
  components: {},
  data() {
    return {
      isReady: false,
      isSaving: false,
      isUploading: false,
      mode: null,
      post: {
        id: 0,
        type: '',
        title_th: '',
        body_th: '',
        title_en: '',
        body_en: '',
        image_path: '',
        status: 0,
      },
      pictureFile: null,
      attachFiles: [],
      imageSrc: null,
      status_list: [
        {
          value: 0,
          text: 'Draft',
        },
        {
          value: 1,
          text: 'Published',
        },
      ],
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          Essentials,
          Autoformat,
          Bold,
          Italic,
          BlockQuote,
          Heading,
          Link,
          List,
          Paragraph,
          Alignment,
          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          ImageResize,
          LinkImage,
        ],

        toolbar: {
          items: [
            'heading',
            '|',
            'alignment',
            'bold',
            'italic',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'undo',
            'redo',
          ],
        },

        image: {
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null,
              icon: 'original',
            },
            {
              name: 'resizeImage:50',
              value: '50',
              icon: 'medium',
            },
            {
              name: 'resizeImage:75',
              value: '75',
              icon: 'large',
            },
          ],
          toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            'resizeImage:50',
            'resizeImage:75',
            'resizeImage:original',
          ],
        },
      },
    };
  },
  mounted() {
    //get category
    this.getData();

    this.$store.dispatch(SET_BREADCRUMB, [{title: 'Post', route: '../'}, {title: 'Edit'}]);
  },
  methods: {
    getData() {
      this.$store
        .dispatch(GET_PARENT)
        // go to which page after successfully login
        .then((res) => {
          res.data.forEach((parent) => {
            if (parent.id != this.post.id) {
              this.parent_list.push({
                value: parent.id,
                text: parent.title,
              });
            }
          });
        })
        .catch((error) => {
          // console.log(error);
        });
      if (this.$route.name == 'about_us.edit') {
        this.mode = 'edit';
        this.id = this.$route.params.id;
        this.$store
          .dispatch(QUERY, {id: this.id})
          // go to which page after successfully login
          .then((res) => {
            this.post = res.data[0];

            this.isReady = true;
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        this.isReady = true;
        this.mode = 'add';
      }
    },
    savePost(mode) {
      return new Promise((resolve, reject) => {
        if (this.post.title_th == '' || this.post.title_en == '') {
          this.notify('danger', 'Error!', 'Please complete the form');
          reject('Please complete the form');
        } else {
          this._savePost(mode);
          resolve();
        }
      });
    },
    _savePost(mode) {
      this.isSaving = true;
      this.$store
        .dispatch(SAVE_POST, this.post)
        // go to which page after successfully login
        .then((res) => {
          if (res.status) {
            this.post = res.data[0];

            //upload banner
            if (this.pictureFile) {
              let formData = new FormData();
              formData.append('file', this.pictureFile);
              this.pictureFile = null;
              this.$store
                .dispatch(UPLOAD_FILE, formData)
                // go to which page after successfully login
                .then((res) => {
                  if (res.status) {
                    this.post.image_path = res.data.image_path;
                  }
                  // console.log('Upload Banner');
                  this.isSaving = false;
                  this.pictureFile = null;
                  //Update image path
                  this.savePost('update_image');
                })
                .catch((error) => {
                  this.isSaving = false;
                  // console.log(error);
                });
            }

            //Upload Attachment
            this.uploadAttachFiles();

            // this.uploadFile();
            if (this.$route.name == 'about_us.add') {
              this.$router.push({
                name: 'about_us.edit',
                params: {id: this.post.id},
              });
            }
          }
          // console.log('Save post');
          this.isSaving = false;
          if (mode != 'update_image') {
            this.notify('success', 'Successfully!', 'Post Saved.');
            if (this.post.status == 1) {
              this.$router.push({
                name: 'about_us.list',
              });
            }
          }
        })
        .catch((error) => {
          this.isSaving = false;
          // console.log(error);
        });
    },
    uploadAttachFiles() {
      if (this.post.id) {
        //Upload Attachment
        if (this.attachFiles) {
          this.isUploading = true;
          const attachNum = this.attachFiles.length;
          let i = 0;
          this.attachFiles.forEach((item, index) => {
            let formData = new FormData();
            formData.append('file', item);
            // console.log(item.name);
            formData.append('id', this.post.id);
            formData.append('table', 'about_us');
            this.$store
              .dispatch(UPLOAD_ATTACH, formData)
              .then((res) => {
                i++;
                if (res.status) {
                  this.notify('success', 'Successfully!', 'Upload [' + res.data.filename + '] successfully!');
                } else {
                  this.notify('success', 'Successfully!', 'Upload [' + item.name + '] successfully!');
                }
                if (i == attachNum) {
                  this.getData();
                }
              })
              .catch((err) => {});
          });
          this.attachFiles = [];
          setTimeout(() => {
            this.isUploading = false;
          }, 1000);
        }
      } else {
        this.savePost();
      }
    },
    deleteAttachment(id) {
      this.isUploading = true;
      this.$store
        .dispatch(DELETE_ATTACH, {id: id})
        .then((res) => {
          this.getData();
          this.isUploading = false;
        })
        .catch((err) => {
          this.isUploading = false;
        });
    },
    copyImageURL(id) {},
    formatNames(files) {
      return files.length === 1 ? files[0].name : `${files.length} files selected`;
    },
    clearImage() {
      this.pictureFile = null;
    },
    clearAttachFiles() {
      this.attachFiles = [];
    },
    notify(variant = null, title, text) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  computed: {
    hasImage() {
      return !!this.pictureFile;
    },
    hasFiles() {
      return !!this.attachFiles.length;
    },
  },
  watch: {
    pictureFile: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
